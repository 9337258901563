import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { noop } from 'lodash';
import InputWrapper from '../InputWrapper/InputWrapper';

const Textarea = forwardRef(({
  autoComplete,
  children,
  className,
  error,
  form,
  helperText,
  id,
  touched,
  label,
  maxLength,
  name,
  onAutoComplete = noop,
  onBlur = noop,
  onChange = noop,
  rows,
  type = 'text',
  ...rest
}, ref) => {
  const value = form?.watch(name);
  const count = useMemo(() => value?.length || 0, [value]);
  const handleBlur = useCallback((e) => {
    if (maxLength) {
      form?.trigger(name);
    }

    onBlur(e);
  }, [
    maxLength,
    name,
    onBlur,
    form,
  ]);

  const isAutoCompleteOff = useMemo(() => autoComplete === 'off', [autoComplete]);
  const [isAutoCompleted, setAutoCompleted] = useState(false);
  const isTouched = useMemo(() => (touched || isAutoCompleted), [isAutoCompleted, touched]);

  useEffect(() => {
    const handleAutoComplete = (e) => {
      if (isAutoCompleteOff || e.target.name !== name) return;

      if (e.target.hasAttribute('autocompleted')) {
        setAutoCompleted(true);
        setTimeout(onAutoComplete, 0);
        return;
      }

      setAutoCompleted(false);
    };

    document.addEventListener('onautocomplete', handleAutoComplete);
    return () => document.removeEventListener('onautocomplete', handleAutoComplete);
  }, [isAutoCompleteOff, name, onAutoComplete]);

  return (
    <InputWrapper
      className={className}
      error={error}
      helperText={helperText}
      label={label}
      labelHtmlFor={id}
      touched={isTouched}
    >
      <div className="Textarea__maxLength__wrapper">
        <TextareaAutosize
          className={clsx('Textarea', {
            'error': isTouched && error,
            'maxLength': isTouched && maxLength,
            'touched': isTouched,
          })}
          id={id}
          name={name}
          minRows={rows}
          onBlur={handleBlur}
          onChange={onChange}
          ref={ref}
          type={type}
          {...(isTouched && { 'aria-invalid': error ? 'true' : 'false' })}
          {...rest}
        >
          {children}
        </TextareaAutosize>
        {maxLength && (() => {
          const delta = count - maxLength;

          return (
            <div className="Textarea__maxLength">
              {count.toLocaleString()} out of {maxLength.toLocaleString()} max characters
              {delta > 0 && (() => {
                const formattedDelta = delta.toLocaleString();

                return (
                  <>
                    &nbsp;&mdash;&nbsp;
                    <strong>
                      {formattedDelta} over the limit!
                    </strong>
                  </>
                );
              })()}
            </div>
          );
        })()}
      </div>
    </InputWrapper>
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
