import React, { forwardRef } from 'react';
import Markdown from 'markdown-to-jsx';
import Wrapper from './components/Wrapper';

const Banner = forwardRef(({ banner, className }, ref) => {
  const { bannerLink, bannerText } = banner;
  const isLink = Boolean(bannerLink);

  return (
    <Wrapper
      className="Banner"
      ref={ref}
      {...(isLink && { to: bannerLink })}
    >
      <Markdown
        className="Banner__bannerText"
        options={{ ...(isLink ? { forceInline: true } : { forceBlock: true }) }}
      >
        {bannerText}
      </Markdown>
    </Wrapper>
  );
});

Banner.displayName = 'Banner';

export default Banner;
