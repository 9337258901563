import React, { forwardRef } from 'react';
import Banner from './components/Banner/Banner';
import Popup from './components/Popup/Popup';

const Announcement = forwardRef(({ announcement, popupClassName }, announcementBannerRef) => (
  <>
    {announcement.showBanner && (() => {
      const { bannerLink, bannerText } = announcement;

      return (
        <Banner
          banner={{ bannerLink, bannerText }}
          ref={announcementBannerRef}
        />
      );
    })()}
    {announcement.showPopup && (() => {
      const { body, buttonLink, buttonText, header } = announcement;

      return (
        <Popup
          className={popupClassName}
          popup={{ body, buttonLink, buttonText, header }}
        />
      );
    })()}
  </>
));

Announcement.displayName = 'Announcement';

export default Announcement;
