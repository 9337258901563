// tileblock.js
import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { Container, Row } from 'react-bootstrap';
import Tiles from '../../../../Tiles/Tiles';
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/arrow-down.svg';

const TileBlock = ({ className, data, ...rest }) => {
  const [isExpanded, setIsExpanded] = useState(!data.dropdownHeader);
  const tilesHeaderAs = useMemo(() => (!data.header ? 'h2' : 'h3'), [data]);

  const handleHeaderClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section
      className={clsx('TileBlock__wrapper', className)}
      {...rest}
    >
      <Container>
        <div className="TileBlock">
          <Row className={`TileBlock__header ${data.dropdownHeader && 'clickable'}`} onClick={data.dropdownHeader ? handleHeaderClick : null}>
            <h3>{data.header}
            {data.dropdownHeader && <ArrowDownIcon className={clsx('TileBlock__arrow', { 'TileBlock__arrow--rotated': isExpanded })} />}</h3>
          </Row>
          <div className={clsx('TileBlock__tiles-wrapper', { 'TileBlock__tiles-wrapper--expanded': isExpanded })}>
            <Tiles
              className="TileBlock__tiles"
              headerAs={tilesHeaderAs}
              tiles={data.tiles}
              displayAsIcons={data.displayAsIcons}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TileBlock;
