import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../../../../../Button/Button';
import Input from '../../../../../Input/Input';
import Textarea from '../../../../../Textarea/Textarea';
import emailjs from '@emailjs/browser';

const Form = ({ className }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');

  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      summary: '',
      company: ''
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data) => {
      emailjs.send('sendgrid', 'template_04ytt0w', data, 'user_pWMPjK77fwBu8FYax4WNT')
        .then((result) => {
          setFormSubmitted(true);
          setFormSubmittedMessage('Thank you for contact us we will be in touch with you soon!')
        }, (error) => {
          setFormSubmitted(true);
          setFormSubmittedMessage('There was an error submitting your form. Please refresh the page and try again.')
        });
    },
    [],
  );

  return (
    <>
      {!formSubmitted ? (<form
        className={className}
        onSubmit={handleSubmit(onSubmit)}
      >
      {console.log(form.formState.isValid)}
        <Row>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.name}
              id="name"
              label="Name*"
              touched={form.formState.touchedFields.name}
              {...form.register('name', {
                required: 'You must enter your name.',
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.phone}
              id="phone"
              label="Phone Number*"
              touched={form.formState.touchedFields.phone}
              type="tel"
              {...form.register('phone', {
                required: 'You must enter your phone number.',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.email}
              id="email"
              label="Email*"
              touched={form.formState.touchedFields.email}
              type="email"
              {...form.register('email', {
                required: 'You must enter your email.',
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.company}
              id="company"
              label="Company*"
              touched={form.formState.touchedFields.company}
              type="company"
              {...form.register('company', {
                required: 'You must enter your company.',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {(() => {
              const maxLength = 1000;

              return (
                <Textarea
                  error={form.formState.errors.summary}
                  form={form}
                  id="summary"
                  label="Tell us a bit about your project"
                  maxLength={maxLength}
                  name="summary"
                  rows={10}
                  trigger={form.trigger}
                  touched={form.formState.touchedFields.summary}
                  watch={form.watch}
                  {...form.register('summary', {
                    maxLength: {
                      value: maxLength,
                      message: `Your text may not be than ${maxLength.toLocaleString()} characters.`,
                    },
                    required: 'You must enter a bit about the reason for reaching out to us.',
                  })}
                />
              );
            })()}
          </Col>
        </Row>
        <Button
          type="submit"
          style={{ width: '100%' }}
          disabled={!form.formState.isValid}
        >
          Submit
        </Button>
      </form>) : (<h3>{formSubmittedMessage}</h3>)}
    </>
  );
};

export default Form;
