import { useMemo } from 'react';

const useIsBlockVisible = (data, keys) => {
  const isVisible = useMemo(
    () => {
      if (!data) return false;
      return Object
        .entries(data)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => key)
        .some((key) => keys.includes(key));
    },
    [data, keys],
  );

  return isVisible;
};

export default useIsBlockVisible;
