import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from './components/Logo/Logo';

function NavBar({menuColor}) {
  console.log(menuColor)
  const [mobileMenu, setMobileMenu] = useState(false);
  const [desktopMenu, setDesktopMenu] = useState(false);
  const [fadeClass, setFadeClass] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [scrollClass, setScrollClass] = useState('');
  const location = useLocation();
  const isCurrentPage = (path) => {
    return location.pathname.startsWith(path);
  };


  const toggleMenu = () => {
    if(isSmallScreen) {
      setMobileMenu(!mobileMenu);
      setDesktopMenu(false);
    } else {
      setDesktopMenu(!desktopMenu);
      setMobileMenu(false);
    }
    if ((!mobileMenu && isSmallScreen) || (!desktopMenu && !isSmallScreen)) {

      setTimeout(() => {
        setFadeClass('fade-in');
      }, 50);
    } else {
      setFadeClass('');
    }
  };

  const closeMobileMenu = () => {
    if (isSmallScreen) {
      setMobileMenu(false);
      setFadeClass('');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };
    const handleScroll = () => {
      if (window.scrollY > 85) {
        setScrollClass('menu-scroll');
      } else {
        setScrollClass('');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set the initial value

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set the initial value

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
<nav id="nav" className={`${(isSmallScreen && mobileMenu) ? 'dark' : menuColor} ${scrollClass}`}>

      <div id="menu__container" className="menu__container" role="navigation">
        <div to="/" className="logo-link">
          <div className="logo-container">
            <Logo
              className="NavBar__logo"
            />
          </div>
        </div>
        <div id="ham__icon" className={`ham ${(isSmallScreen ? mobileMenu : desktopMenu) ? 'is-active' : ''}`} onClick={toggleMenu}>
          <div id="ham__icon--wrapper" className="ham-hamburger__wrapper">
            <div className="ham-hamburger"></div>
          </div>
          <ul className="list-inline">
            <li className="list-inline-item">
              <Link to="/contact" className={isCurrentPage('/contact') ? 'current-page' : ''}><span>Contact</span></Link>
            </li>
            {/* <li className="list-inline-item">
              <Link to="/insights"><span>Insights</span></Link>
            </li> */}
            <li className="list-inline-item">
              <Link to="/about" className={isCurrentPage('/about') ? 'current-page' : ''}><span>About</span></Link>
            </li>
            <li className="list-inline-item">
              <Link to="/?scrollTo=works"><span>Work</span></Link>
            </li>
            <li className="list-inline-item">
              <Link to="/expertise" className={isCurrentPage('/expertise') ? 'current-page' : ''}><span>Expertise</span></Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {mobileMenu && isSmallScreen ? (
        <div className="mobile-menu">
          <div className="overlay"></div>
          <div className={`mobile-menu-items ${fadeClass}`}>
            <Link to="/expertise" onClick={closeMobileMenu} className={isCurrentPage('/expertise') ? 'current-page' : ''}>
              <span>Expertise</span>
            </Link>
              <Link to="/?scrollTo=works" onClick={closeMobileMenu}><span>Work</span></Link>
            <Link to="/about" onClick={closeMobileMenu} className={isCurrentPage('/about') ? 'current-page' : ''}>
              <span>About</span>
            </Link>
            {/* <Link to="/insights" onClick={closeMobileMenu}>
              <span>Insights</span>
            </Link> */}
            <Link to="/contact" onClick={closeMobileMenu} className={isCurrentPage('/contact') ? 'current-page' : ''}>
              <span>Contact</span>
            </Link>
          </div>
        </div>
      ) : ''}
    </>
  )
};

export default NavBar;

