import React, { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Page from '../views/Page/Page';
import NotFound from '../views/NotFound/NotFound';

const DynamicRouter = ({
  announcement,
  footer,
  works,
  pages,
  expertises,
}) => {
  const orderedPages = useMemo(
    () =>
      pages
        .reduce((x, page) => {
          const isHomepage = page.attributes.slug === 'home';
          if (isHomepage) return [{ path: '/', ...page }, ...x];
          const path = `/${page.attributes.slug}`;
          return [...x, { path, ...page }];
        }, [])
        .reverse(),
    [pages],
  );

  const renderPagesRoutes = () =>
    orderedPages.map((page) => (
      <Route
        key={page.attributes.slug}
        path={page.path}
        element={<Page announcement={announcement.attributes} footer={footer.attributes} works={works} page={page.attributes} />}
      />
    ));

  const renderWorksRoutes = () =>
    works.map((work) => (
      <Route
        key={work.attributes.slug}
        path={`works/${work.attributes.slug}`}
        element={<Page announcement={announcement.attributes} footer={footer.attributes} works={works} page={work.attributes} additionalClasses={'Works'} />}
      />
    ));

  const renderExpertisesRoutes = () =>
    expertises.map((expertise) => (
      <Route
        key={expertise.attributes.slug}
        path={`expertise/${expertise.attributes.slug}`}
        element={<Page announcement={announcement.attributes} footer={footer.attributes} expertises={expertises} page={expertise.attributes} additionalClasses={'Expertises'} />}
      />
    ));

  return (
    <Router>
      <Routes>
        {renderPagesRoutes()}
        {renderWorksRoutes()}
        {renderExpertisesRoutes()}
        <Route path="*" element={<NotFound footer={footer.attributes} /> } />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default DynamicRouter;
