import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as SVGlogo } from '../../assets/footer-logo.svg';
import CompanyInfo from '../CompanyInfo/CompanyInfo';
// import CookiePolicy from '../CookiePolicy/CookiePolicy';
import LongButton from '../LongButton/LongButton';
import { initGA } from '../../utils/ga-utils';
import ChatBot from '../ChatBot/ChatBot';

const Footer = ({ className, companyInfo, footer }) => {
  const numberToText = (num) => {
    const ones = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'
    ];
    const teens = [
      'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const tens = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];

    if (num < 10) return ones[num];
    if (num >= 10 && num < 20) return teens[num - 10];
    if (num >= 20) {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + ones[num % 10] : '');
    }
  };

  const convertYearToText = (year) => {
    const hundreds = Math.floor((year % 1000) / 100);
    const tensOnes = year % 100;

    const hundredsText = hundreds > 0 ? numberToText(hundreds) + ' hundred' : '';
    const tensOnesText = numberToText(tensOnes);

    return `Twenty ${hundredsText} ${tensOnesText}`.trim();
  };

  const year = useMemo(() => new Date().getFullYear(), []);
  const yearText = convertYearToText(year);


  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <footer className={clsx('Footer__wrapper', className)}>
      <div className="Footer__conactCallout gray">
        <h3>Have an idea and want to talk about it? Get in touch.</h3>
        <p>Feel free to reach out anytime. We'd love to discuss any upcoming projects you may be interested in.</p>
          <div className="svg-container contact-callout-button">
             <LongButton text={'Work with Us'} link='/contact' isExternal={false} className="dark black"/>
          </div>
        </div>
      <div className="Footer">
        <Container>
          <Link
            className="Footer__logo__wrapper"
            to="/"
          >
            <SVGlogo className="Footer__logo" />
          </Link>

          <CompanyInfo companyInfo={companyInfo} />

          <div className="Footer__pageLinks">
            <span>
              The Collective Creative, LLC. &#169; {yearText}
            </span>
          </div>
        </Container>
      </div>
      <div>
        {/* <CookiePolicy /> */}
        <ChatBot />
      </div>
    </footer>
  );
};

export default Footer;
