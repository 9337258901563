import React, { useState, useEffect, useRef } from 'react';
// import './DeviceStudyBlock.css';
import { Col } from 'react-bootstrap';
import { clsx } from 'clsx';
import { Row } from 'react-bootstrap';
import Helpers from '../../../../../utils/Helpers';
const DeviceStudyBlock = ({ data, className }) => {
  console.log(data)
  const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const deviceStudyRef = useRef(null);
  const scrollerContainerRef = useRef(null);
  const deviceContainerRef = useRef(null);


  useEffect(() => {
    const deviceStudyBlock = deviceStudyRef.current;
    const deviceContainer = deviceContainerRef.current;
    let prevScrollPos = deviceStudyBlock.offsetTop;

    const handleScroll = () => {
      const scrollPos = window.scrollY;
      // console.log(scrollPos)
      const deviceStudyTop = deviceStudyBlock.offsetTop;
      const deviceStudyBottom = deviceStudyTop + deviceStudyBlock.offsetHeight;
      const windowHeight = window.innerHeight;
      const deviceCount = data.devices.length-1;
      const scrollDistance = windowHeight * deviceCount;
      const scrollThreshold = (deviceStudyTop + scrollDistance) - windowHeight;
      console.log('PSP: '+prevScrollPos,'SP: '+scrollPos,'dst: '+deviceStudyTop,'ST: '+scrollThreshold, "bottom: "+deviceStudyBottom, "count: "+deviceCount)

      if (scrollPos >= deviceStudyTop && scrollPos <= deviceStudyBottom && currentDeviceIndex <= deviceCount) {
        const newDeviceIndex = Math.floor((scrollPos - deviceStudyTop) / (windowHeight / deviceCount));
        // console.log(newDeviceIndex)
        if (newDeviceIndex !== currentDeviceIndex && newDeviceIndex <= deviceCount) {
          console.log(newDeviceIndex)
          setCurrentDeviceIndex(newDeviceIndex);
        }
        deviceContainer.style.position = `fixed`;
        deviceContainer.style.top = `0`;
        
        
        console.log('whatever happend');
      } else if (scrollPos > scrollThreshold && prevScrollPos < deviceStudyBottom) {
        // deviceContainer.style.position = 'fixed'
        deviceContainer.style.top = `initial`;
        deviceContainer.style.position = `relative`;
        console.log('this happend');
      } else if (scrollPos < prevScrollPos && prevScrollPos >= deviceStudyTop) {
        console.log('that happend');
        deviceContainer.style.top = `initial`;
        deviceContainer.style.position = `relative`;
      }

      prevScrollPos = scrollPos;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentDeviceIndex, data]);

  const currentDevice = data.devices[currentDeviceIndex];
  console.log(currentDeviceIndex)

  return (
    <section
      className={clsx('DeviceStudyBlock', className)}
      ref={deviceStudyRef}
      
    >
    <div className="DeviceStudyBlock__wrapper">
    <div class={`scroll-wrapper ${isScrolling ? 'scroller':''}`} ref={scrollerContainerRef}>
      <Row className="DeviceStudyBlock__content" ref={deviceContainerRef}>
        <Col sm={12} md={7} className="device-container">
          <div className="main clearfix">
            <div className="md-slider">
              <div className="md-device-wrapper">
                <div className={`md-device md-device-${currentDevice?.deviceType}`}>
                  <div className="dev-wrap"><div className="image" style={{ backgroundImage: `url('${Helpers.getImageUrl(currentDevice?.image, "large")}')` }}></div></div>
                  <div className="md-border-element"></div>
                  <div className="md-base-element"></div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={5} className="text-blurb justify-content-center align-self-center">
          <div class="text-wrapper desktop-text-wrapper">
            <div class="text-block">
              <div class="text-inner">
                <h2>{currentDevice.title}</h2>
                <p>{currentDevice.body}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </div>
      <div className="text-wrapper mobile-text-wrapper">
        {data.devices.map((item, index) => {
          if (item) {
            return (
              <React.Fragment key={index}>
                <div className="image-container">
                  <div className={`md-device md-device-${item.deviceType}`}>
                    <div className="dev-wrap">
                      <div
                        className="image"
                        style={{ backgroundImage: `url('${Helpers.getImageUrl(item.image, "large")}')` }}
                      ></div>
                    </div>
                    <div className="md-border-element"></div>
                    <div className="md-base-element"></div>
                  </div>
                </div>
                <div className="text-block-mobile">
                  <div className="text-inner">
                    <h2>{item.title}</h2>
                    <p>{item.body}</p>
                  </div>
                </div>
              </React.Fragment>
            );
          }
          return null;
        })}
      </div>
      </div>
    </section>
  );
};

export default DeviceStudyBlock;