import React from 'react';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';

const ContactHeroBlock = ({ data, className }) => {
  console.log(data.image.data.attributes)

  return (
    <section className={clsx('ContactHeroBlock', className)}>
      <Row className="align-items-center justify-content-center">
        <Col xs={{span:12, order:2}} lg={{span:6, order:1}} className="ContactHeroBlock__text-wrapper">
          <h1>{data.header}</h1>
          <Markdown options={{ forceBlock: true }}>{data.body}</Markdown>

        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className="ContactHeroBlock__image-wrapper">
          <img src={data.image.data.attributes.formats.medium.url} alt={data.image.data.attributes.name} />
        </Col>
      </Row>

    </section>
  );
};

export default ContactHeroBlock;
