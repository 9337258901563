import React from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';

const ErrorMessage = ({ className, error }) => (
  <Markdown
    className={clsx('ErrorMessage', className)}
    options={{ forceBlock: true }}
    role="alert"
  >
    {error.message}
  </Markdown>
);

export default ErrorMessage;
