import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LongButton from '../../../LongButton/LongButton';

const HomeHeroBlock = ({ data, className, ...rest }) => {
  const [opacity, setOpacity] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    const targetHeight = 650;
  
    const handleScroll = () => {
      const scrollPercent = (targetHeight - window.scrollY) / targetHeight;
      if (scrollPercent >= 0) {
        setOpacity(scrollPercent);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    const startTimer = () => {
      timerRef.current = setInterval(() => {
        const cur = document.querySelector('.hero-container .hero-header.active');
        cur.classList.remove('active');
        const next = cur.nextElementSibling || document.querySelector('.hero-container .hero-header:first-child');
        next.classList.add('active');
        setActiveIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % data.tiles.length;
          return newIndex;
        });
      }, 5000);
    };
  
    const clearTimer = () => {
      clearInterval(timerRef.current);
      document.querySelector('.progress-bar').style.display = 'none';
    };
  
    const restartTimer = () => {
      document.querySelector('.progress-bar').style.display = 'block';
      startTimer();
    };
  
    document.querySelector('.hero-container').addEventListener('mouseenter', clearTimer);
    document.querySelector('.hero-container').addEventListener('mouseleave', restartTimer);
  
    startTimer();
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      const heroContainer = document.querySelector('.hero-container');
  if (heroContainer) {
    heroContainer.removeEventListener('mouseenter', clearTimer);
    heroContainer.removeEventListener('mouseleave', restartTimer);
  }
      clearInterval(timerRef.current);
    };
  }, [data.tiles.length]);
  

  const handleSlideToWork = () => {
    const worksElement = document.querySelector('.FeaturedProjectsBlock');
    if (worksElement) {
      const distanceFromTop = worksElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: distanceFromTop-85, behavior: 'smooth' });
    }
  };

  return (
    <section className="homepage-above-fold-container above-fold-container" style={{ opacity }}>

      <div
        className="replacement-image ember-background-video"
        style={{ backgroundImage: `url(${data.image.data.attributes.url})` }}
        aria-label="Background image"
      >
        <div className="overlay"></div>
        <div className="text-carousel">
          <div className="carousel-cotent">
            <div className="progress-container">
              <div className="progress-bar">
                <div className="loaded"></div>
              </div>
            </div>
            <header className="hero-container">
              {data.tiles.map((item, index) => (
                <div key={index} className={`hero-header ${index === activeIndex ? 'active' : ''}`}>
                  <div className="typed-container">
                    <h1>{item.header}</h1>
                  </div>
                  <p>{item.body}</p>
                  {item.linkSlug === 'works' ? (
                    <div className="svg-container" >
                    <div className='LongButton' onClick={handleSlideToWork}>
                      <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg" className="gradient">
                        <g>
                          <rect height="60" width="320" className="rect-shape"></rect>
                          <text
                            x="50%"
                            y="50%"
                            alignmentBaseline="middle"
                            fontSize="35"
                            textAnchor="middle"
                            className="text"
                          >
                            {item.linkText}
                          </text>
                        </g>
                      </svg>
                      </div>
                    </div>
                  ) : (
                    <div className="svg-container">
                      <Link to={item.linkSlug} aria-label={`Go to ${item.linkText}`}>
                        <LongButton link={item.linkSlug} className="long-button" isExternal={false} text={item.linkText}/>
                      </Link>
                    </div>
                  )}
                </div>
              ))}
            </header>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHeroBlock;