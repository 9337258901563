import React, { useMemo } from 'react';
import { get } from 'lodash';
import ButtonComponent from '../../../../../Button/Button';

const Button = ({ button, className }) => {
  const {
    buttonLink,
    buttonText,
    disabled,
    onClick,
    page,
  } = button;

  const url = useMemo(
    () => {
      if (buttonLink) return buttonLink;
      const slug = get(page, 'data.attributes.slug');
      return slug ? `/${slug}` : null;
    },
    [buttonLink, page],
  );

  if (!url && !onClick) return null;

  return (
    <ButtonComponent
      disabled={disabled}
      onClick={onClick}
      {...(url && { to: url })}
    >
      {buttonText}
    </ButtonComponent>
  );
};

export default Button;
