import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const Wrapper = forwardRef(({ children, to, ...rest }, ref) => (
  to
    ? (
      <Link to={to} ref={ref} {...rest}>
        {children}
      </Link>
    )
    : (
      <aside ref={ref} {...rest}>
        {children}
      </aside>
    )
));

Wrapper.displayName = 'Wrapper';

export default Wrapper;
