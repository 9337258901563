import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import BackgroundImage from '../../../BackgroundImage/BackgroundImage';
import useHeaderAs from '../../hooks/useHeaderAs';
import Markdown from 'markdown-to-jsx';
import LongButton from '../../../LongButton/LongButton';

const AboutHeroBlock = ({ data, className, ...rest }) => {
  console.log(data)
  const headerTextColor = 'white';
  const [ref, headerAs] = useHeaderAs('h1', 'h2');

  return (
    <section className={clsx('AboutHeroBlock', className)}>
      <div
        className={clsx('AboutHeroBlock__header__wrapper', headerTextColor)}
        ref={ref}
        {...rest}
        
      >
        {headerAs && (() => {
          const Header = headerAs;
          return (
            <Header className="AboutHeroBlock__header">
              {parse(data.title)}
            </Header>
           
          );
        })()}
        <Markdown options={{ forceBlock: true }}>{data.body}</Markdown>
        <LongButton text={data.linkText} link={data.linkUrl} isExternal={false} color={'white'} />
        
        
      </div>
      
      <BackgroundImage
        className="AboutHeroBlock__backgroundImage"
        image={data.image}
        full={true}
      />
    </section>
  );
};

export default AboutHeroBlock;
