import React, { useMemo } from 'react';
import clsx from 'clsx';
import { get, has } from 'lodash';
import { Helmet } from 'react-helmet';

const BackgroundImage = ({ className, image, full }) => {
  const [alternativeText, formats] = useMemo(() => [
    get(image, 'data.attributes.alternativeText'),
    get(image, 'data.attributes.formats'),
  ], [image]);

  if (!has(formats, 'large.url')) return null;

  const imageUrl = full
    ? (has(formats, 'xlarge.url') ? get(image, 'data.attributes.formats.xlarge.url') : formats.large.url)
    : formats.large.url;

  return (
    <>
    <Helmet>
        <link rel="preload" href={imageUrl} as="image" />
      </Helmet>
    <div className={clsx('BackgroundImage', className)}>
      <img
        alt={alternativeText}
        className="BackgroundImage__img"
        src={imageUrl}
      />
    </div>
    </>
  );
};

export default BackgroundImage;
