import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as SVGLogo } from '../../../../../assets/success-icon.svg';


const ImageWithListBLock = ({ className, data, ...rest }) => {
  console.log(data);

  return (
    <section
      className={clsx('ImageWithListBLock__wrapper', className)}
      {...rest}
    >
      <div className="image-list-wrapper">
        <Container className="image-list-container">
          <Row>
            <Col lg={7}>
              <img src={data.image.data.attributes.url} alt="" className="img-fluid sub-image" />
            </Col>
            <Col lg={5}>
              {data.lists && (
                <ul className="expertise-list">
                  {data.lists.map((list, index) => (
                    <li key={index} className="expertise-item">
                      <SVGLogo alt="" className="icon" />
                      {list.text}
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ImageWithListBLock;
