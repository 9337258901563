import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const LongButton = ({ className, link, isExternal,text, color }) => {
  console.log(isExternal)
  return (
<div className={clsx('LongButton', [className, color])}>
  {!isExternal ? <Link to={link} aria-label={text}>
    <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg" className="gradient">
      <g>
        <rect height="60" width="320" className="rect-shape"></rect>
        <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fontSize="35" className="text">{text}</text>
      </g>
    </svg>
  </Link> : (<a href={link} target="_blank" aria-label={text} rel="noreferrer">
  <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg" className="gradient">
      <g>
        <rect height="60" width="320" className="rect-shape"></rect>
        <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fontSize="35" className="text">{text}</text>
      </g>
    </svg>
  </a>) }
</div>
  );
};

export default LongButton;
