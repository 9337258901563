import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../../utils/Helpers';

const ButtonWrapper = forwardRef(({
  as = 'button',
  children,
  type = 'button',
  ...rest
}, ref) => {
  const Tag = as;
  const isButton = as === 'button';

  return (
    <Tag
      ref={ref}
      {...(isButton && { type })}
      {...rest}
    >
      {children}
    </Tag>
  );
});


const LinkWrapper = forwardRef(({ children, ...rest }, ref) => {
  if (Helpers.linkChecker(rest.to)) {
    return <a className="Button" href={rest.to} target="_blank" rel="noreferrer"> {children}</a>
  } else {
    return <Link ref={ref} {...rest}>
      {children}
    </Link>

  }
});

const Wrapper = forwardRef(({
  as,
  children,
  to,
  ...rest
}, ref) => (
  to
    ? (
      <LinkWrapper to={to} ref={ref} {...rest}>
        {children}
      </LinkWrapper>
    )
    : (
      <ButtonWrapper as={as} ref={ref} {...rest}>
        {children}
      </ButtonWrapper>
    )
));

ButtonWrapper.displayName = 'ButtonWrapper';
LinkWrapper.displayName = 'LinkWrapper';
Wrapper.displayName = 'Wrapper';

export default Wrapper;
