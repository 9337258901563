// App.jsx
import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import DynamicRouter from './utils/DynamicRouter';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import { fetchAppData } from './utils/api';

const App = () => {
  const [appData, setAppData] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    trackPromise(fetchAppData(source.token).then(setAppData).catch((error) => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error fetching data:', error);
        // Handle the error, e.g., show an error message
      }
    }));
    AOS.init({ duration: 600 });

    return () => {
      source.cancel('Request canceled due to component unmounting');
    };
  }, []);

  return (
    <div className="app">
      <LoadingIndicator hasLogo={true} />
      {appData && <DynamicRouter {...appData} />}
    </div>
  );
}

export default App;
