import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';
import BackgroundImage from '../../../../BackgroundImage/BackgroundImage';
import useHeaderAs from '../../../hooks/useHeaderAs';

const StudyImageBlock = ({ data, className, ...rest }) => {
  console.log(data)
  const headerTextColor = 'white';
  const [ref, headerAs] = useHeaderAs('h1', 'h2');

  return (
    <section
      className={clsx('StudyImageBlock__wrapper', className)}
      ref={ref}
      {...rest}
    >
        <div className="StudyImageBlock">
          <div className={clsx('StudyImageBlock__header__wrapper', headerTextColor)}>
                  <img className="LogoBlock__logos" src={data.logo.data.attributes.url} />
          </div>
          <BackgroundImage
            className="StudyImageBlock__backgroundImage"
            image={data.background}
          />
        </div>
    </section>
  );
};

export default StudyImageBlock;
