import React, { useMemo } from 'react';
// import DividerBlock from './components/ContentBlocks/DividerBlock/DividerBlock';
import HeaderParagraphBlock from './components/ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock';
// import TextColumnsBlock from './components/ContentBlocks/TextColumnsBlock/TextColumnsBlock';
// import ClaimFormBlock from './components/FormBlocks/ClaimFormBlock/ClaimFormBlock';
import ContactFormBlock from './components/FormBlocks/ContactFormBlock/ContactFormBlock';
import GlobalHeroBlock from './components/HeroBlocks/GlobalHeroBlock';
import AboutHeroBlock from './components/HeroBlocks/AboutHeroBlock';
import HomeHeroBlock from './components/HeroBlocks/HomeHeroBlock';
// import ImageTitleBlock from './components/ImageBlocks/ImageTitleBlock/ImageTitleBlock';
// import SliderBlock from './components/ImageBlocks/SliderBlock/SliderBlock';
import TileBlock from './components/ImageBlocks/TileBlock/TileBlock';
// import MapBlock from './components/ContentBlocks/MapBlock/MapBlock';
// import TextWithFactsBlock from './components/ContentBlocks/TextWithFactsBlock/TextWithFactsBlock';
// import ProductBlock from './components/AccordionBlocks/ProductBlock/ProductBlock';
// import FaqAccordionBlock from './components/AccordionBlocks/FaqAccordionBlock/FaqAccordionBlock';
// import InfographicBlock from './components/ImageBlocks/InfographicBlock/InfographicBlock';
// import AgentFormBlock from './components/FormBlocks/AgentFormBlock/AgentFormBlock';
// import HeaderButtonBlock from './components/ContentBlocks/HeaderButtonBlock/HeaderButtonBlock';
// import PaymentFormBlock from './components/FormBlocks/PaymentFormBlock/PaymentFormBlock';
import FeaturedProjectsBlock from './components/ImageBlocks/FeaturedProjectsBlock/FeaturedProjectsBlock';
import LogoBlock from './components/ImageBlocks/LogoBlock/LogoBlock';
import WorkHeroBlock from './components/HeroBlocks/WorkHeroBlock';

import DeviceStudyBlock from './components/ContentBlocks/DeviceStudyBlock/DeviceStudyBlock';
import StudyImageBlock from './components/ImageBlocks/StudyImageBlock/StudyImageBlock';
import ContactHeroBlock from './components/HeroBlocks/ContactHeroBlock';
import ExpertiseHeroLandingBlock from './components/HeroBlocks/ExpertiseHeroLandingBlock';
import ImageWithListBLock from './components/ImageBlocks/ImageWithListBlock/ImageWithListBlock';

const blockComponents = {
  // 'content-block.divider-block': DividerBlock,
  'content-block.header-paragraph-block': HeaderParagraphBlock,
  'content-block.device-study-block': DeviceStudyBlock,
  // 'content-block.text-columns-block': TextColumnsBlock,
  // 'content-block.map-block': MapBlock,
  // 'content-block.text-with-facts': TextWithFactsBlock,
  // 'accordion-block.product-block': ProductBlock,
  // 'accordion-block.faq-accordion-block': FaqAccordionBlock,
  'form-block.contact-form-block': ContactFormBlock,
  // 'form-block.agent-form-block': AgentFormBlock,
  // 'form-block.claim-form-block': ClaimFormBlock,
  // 'form-block.payment-form-block': PaymentFormBlock,
  'hero-block.global-hero-block': GlobalHeroBlock,
  'hero-block.home-hero-block': HomeHeroBlock,
  'hero-block.work-hero-block': WorkHeroBlock,
  'hero-block.about-hero-block': AboutHeroBlock,
  'hero-block.contact-hero-block': ContactHeroBlock,
  'hero-block.expertise-landing-hero-block': ExpertiseHeroLandingBlock,
  'image-block.featured-projects-block': FeaturedProjectsBlock,
  'image-block.logo-block': LogoBlock,
  'image-block.image-with-list-block': ImageWithListBLock,
  'image-block.study-image-block': StudyImageBlock,
  'image-block.tile-block': TileBlock,
  // 'image-block.infographic-block': InfographicBlock,
};

const PageBlocks = ({ pageBlocks, works }) => {
  const blocks = useMemo(
    () => (
      pageBlocks
        .map(({ __component: name, ...data }) => {
          const BlockComponent = blockComponents[name];
          if (!BlockComponent) return null;

          const id = `${name}-${data.id}`;
          const className = name.substr(name.indexOf('.') + 1);
          // const isMapBlock = name === 'content-block.map-block';

          return {
            BlockComponent,
            className,
            data,
            id,
            works
            
          };
        })
        .filter(Boolean)
    ),
    [pageBlocks, works],
  );

  return (
    <div className="PageBlocks">
      {blocks.map(({
        BlockComponent,
        className,
        data,
        id,
        works
      }, i) => (
        <BlockComponent
          className={className}
          data={data}
          data-aos="fade-up"
          data-aos-once="true"
          data-index={i}
          id={id}
          key={id}
          works={works}
        />
      ))}
    </div>
  );
};

export default PageBlocks;
