import React from 'react';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';

const LogoBlock = ({ className, data}) => {

  return (
    <section
      className={clsx('LogoBlock__wrapper', className)}
    >
      <Container>
        <div className="LogoBlock">
        {data.header &&
        <Row>
          <Markdown options={{ forceBlock: true }} className="text-center">{'### '+data.header}</Markdown>
        </Row>
        }
          <Row className="justify-content-center">
            {data.logos.map(({ header, id, image, url }) => (
              <Col lg={2} md={4} sm={4} xs={6} className="LogoBlock__col">
                {url ? (
                  <a href={url} target="_blank" aria-label={header} rel="noreferrer">
                    <img className="LogoBlock__logos" src={image.data.attributes.url} alt={image.data.attributes.name} />
                  </a>
                ) : (
                  <img className="LogoBlock__logos" src={image.data.attributes.url} alt={image.data.attributes.name} />
                )}

              </Col>

            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default LogoBlock;