import React from 'react';
import clsx from 'clsx';
import { Container, Col, Row } from 'react-bootstrap';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';
import Form from './components/Form';
import { ReactComponent as SVGlinkedin } from '../../../../../assets/linkedin.svg';
import { ReactComponent as SVGInstagram } from '../../../../../assets/instagram.svg';
import { ReactComponent as SVGTwitter } from '../../../../../assets/twitter.svg';


const ContactFormBlock = ({ data, className, ...rest }) => {
  // TODO: wire up strapi to return options for `white`, `black`
  const isVisible = useIsBlockVisible(data, ['header', 'displayForm']);

  if (!isVisible) return;

  return (
    <section
      className={clsx('ContactFormBlock__wraper', className)}
      {...rest}
    >
      <Container fluid>
        <div className="ContactFormBlock">
          <Row>

            <Col sm={12} md={12} lg={4}>
              <div className="contact-details">
                <h2>Get in touch.</h2>
                <p><svg className="contact-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 482"><path d="M318.2 231.1c-10.2-17.1-16.5-40.9-31.5-49.6-22-12.7-52.8-23.9-75.1-18-19.9 5.3-46.7 35.1-46.2 53.3.7 22.1 22.2 51.9 43 63.6 60.7 34.3 137.3 8.7 165-48.9 28.1-58.4 4.3-125.2-60.1-156.7-72.7-35.5-145.7-36.7-213 14.7-51.2 39.1-69 104.9-49.7 173.9 20.7 73.8 92.5 137.8 164.6 145.1 130.3 13.1 231.4-41.8 257.3-144 1.5-5.9 8.3-10.4 12.7-15.5 5 1.4 9.9 2.7 14.9 4.1-6.7 30.6-7.7 63.9-21.1 91.2-47.3 96.3-131.6 137-234.9 138-96.3 1-171.8-39.7-214.9-128.8-45-93-39.1-183.9 26.4-265 66.1-81.9 156.6-101 255.3-80.6 83.8 17.3 134.8 71.3 150.9 156.3 17.4 92-47.8 185.6-146.7 211C219.8 399.6 122 351 94.5 265.5c-20.7-64.4 12.6-128.2 79.8-153.1 66.9-24.7 126.6-1.3 148.3 60.4 6.1 17.4 5 37.2 7.1 56-3.8.8-7.6 1.5-11.5 2.3z"></path></svg><a href="tel:1-323-410-7221">+1 (323) 410-7221</a></p>
                <p><svg className="contact-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 460"><path d="M92.6 298c-43.2 8-77.1-4.9-88.8-49.1-12-45.2 4.4-82.8 47-104.3 47.8-24.1 94.6-17.8 137.8 13.1 15.8 11.3 30 24.7 49.5 41 4.2-48.2 5-94.8-38.5-111.7-22.6-8.8-57.5 13.6-98.6 25.3-2-14-14.6-41.9-6.3-60.4 9.5-21 35.5-42.2 58.2-48 57.8-14.9 94.4 21.9 124.2 66v130.7c39.2-22 78.5-42.3 71.2-94.3-5.9-41.6-41.4-36.7-71.9-35.6-7.1-45.2 20.3-64.2 58-67.2 46.5-3.7 74.5 25.4 84.5 67.8 5.6 23.8 1 49.9 1 79.5 41.8 10.7 81.5 27.8 79.8 82.5-1.9 62.5-48 83.3-96.9 99.7 14.1 78.5-8.6 121.1-66.5 126.9C280 465.3 223.9 413 219.8 349c-1.8-28-.3-56.3-.3-88.3-35.1 16.1-73.3 37.1-67.4 77.9 3.8 26.5 37.7 48.6 64.5 80.1-11.5 39.6-67.9 55.3-109.1 13.9-39.9-40.1-37.2-86.8-15-134.8 37.5-26.3 75-52.6 111.6-78.2-35.6-28.4-83.7-38.2-108.1-19.5-38.5 29.4-10.3 61.4-3.1 92.5.3 1.7-.2 3.6-.3 5.4zm172.2-30c-8.5 60.4 9.8 108 46.1 110.4 17.1 1.1 35.4-14.9 53.2-23.2-8.1-12.5-13.7-28.1-24.7-36.9-23.2-18.4-49.2-33.4-74.6-50.3zm155.8-84.7c-6.8-1.7-13.6-3.3-20.4-5-36.7 18.9-73.3 37.8-116.2 59.9 45.7 21.5 85 50.9 124.1 12.1 13.3-13.2 8.9-44.2 12.5-67z"></path></svg><a href="mailto:hello@thecollective.io">hello@thecollective.io</a></p>
                <div className="social-icons">
                  <a href="https://www.linkedin.com/company/the-collective-development-agency/" target="_blank" rel="noreferrer"><SVGlinkedin /></a>
                  <a href="https://twitter.com/collective_io" target="_blank" rel="noreferrer"><SVGTwitter /></a>
                  <a href="https://www.instagram.com/the.collective.io/" target="_blank" rel="noreferrer"><SVGInstagram /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={8}>
              {data.displayForm && <Form className="ContactFormBlock__form" />}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ContactFormBlock;
