import React, { useState } from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ExpertiseHeroLandingBlock = ({ data, className }) => {
  const [selectedOption, setSelectedOption] = useState(null);


  const handleOptionHover = (option) => {
    setSelectedOption(option);
  };

  const handleOptionLeave = () => {
    setSelectedOption(null);
  };

  return (
    <section className={clsx('ExpertiseHeroLandingBlock d-flex justify-content-center align-items-center', className)}>
      <Container style={{ backgroundImage: `url("${data.background.data.attributes.url}")` }}>
      <Row className="ExpertiseHeroLandingBlock__row">
          <Col lg={8} className="ExpertiseHeroLandingBlock__text d-none d-lg-block">
            <h1 className={selectedOption ? 'selected' : ''}>{selectedOption ? selectedOption.header : data.header}</h1>
            <Markdown className={selectedOption ? 'selected' : ''} options={{ forceBlock: true }}>{selectedOption ? selectedOption.body : data.body}</Markdown>
            <div className="ExpertiseHeroLandingBlock__overlay"></div>
          </Col>
          <Col lg={'auto'} className="ExpertiseHeroLandingBlock__menu col-lg-auto d-flex flex-column flex-grow-1">
            <Col sm={12} className="ExpertiseHeroLandingBlock__menu_header">
              <h3>Things we are good at.</h3>
            </Col>
            {data.expertises.map((expertise) => (
              <Link to={`/expertise/${expertise.expertise.data.attributes.slug}`} key={expertise.id}>
              <Col sm={12} className="ExpertiseHeroLandingBlock__selector" onMouseEnter={() => handleOptionHover(expertise)}
                onMouseLeave={handleOptionLeave}>
                  <Row>
                    <Col xs={9} sm={10}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={expertise.icon.data.attributes.formats.thumbnail.url} alt={expertise.icon.data.attributes.name} />
                        <h3>{expertise.title}</h3>

                      </div>
                    </Col>
                    <Col xs={3} sm={2}>
                      <div className="arrow">
                        <span className="right"></span>
                      </div>
                    </Col>
                  </Row>
                
              </Col>
              </Link>
            ))}

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ExpertiseHeroLandingBlock;
