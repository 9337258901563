// featured-project.jsx
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Container, Col, Row } from 'react-bootstrap';
import HeaderParagraphBlock from '../../ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock';
import useHeaderAs from '../../../hooks/useHeaderAs';
import { Link } from 'react-router-dom';
import GetSVG from './GetSVG';
import { Element, scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';

const FeaturedProjectsBlock = ({ className, data, ...rest }) => {
  const [ref, headerAs] = useHeaderAs('h1', 'h2');

  const projectLayout = (index) => {
    if (index === 0) return { sm: 12 };
    if (index === 1) return { sm: 12, md:6 };
    if (index === 2) return { sm: 6, md: 12 };
  };
  const location = useLocation();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const scrollTo = urlSearchParams.get('scrollTo');

    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location]);

  return (
    <section
      className={clsx('FeaturedProjectsBlock__wrapper', className)}
      ref={ref}
      {...rest}
      id="FeaturedProjectBlock"
    >
      <Container>
      <Element name="works">
        <div className="FeaturedProjectsBlock">
          <HeaderParagraphBlock
            as="div"
            data={{
              body: data.body,
              header: data.header,
            }}
            headerAs={headerAs}
          />
          <Row className="grid projects-grid" id="works">
            {data.works.slice(0, 2).map((project, index) => (
              <Col {...projectLayout(index)} className="grid-item-wrapper first-wrappers">
                {project.isExternalLink ? (
                  <a href={`${project.link}`} key={project.link} target="_blank" rel="noopener noreferrer">
                    <div
                      className={`grid-item`}
                      style={{
                        backgroundImage: `url(${project.image.data.attributes.url})`,
                      }}
                    >
                      <div className="grid-item-overlay"></div>
                      <div className="grid-item-inset"></div>
                      <div className={`svg-container-project ${project.link}`}>
                        <GetSVG url={project.logo.data.attributes.url} />
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link to={`/works/${project.link}`} key={project.link}>
                    <div
                      className={`grid-item`}
                      style={{
                        backgroundImage: `url(${project.image.data.attributes.url})`,
                      }}
                    >
                      <div className="grid-item-overlay"></div>
                      <div className="grid-item-inset"></div>
                      <div className={`svg-container-project ${project.link}`}>
                        <GetSVG url={project.logo.data.attributes.url} />
                      </div>
                    </div>
                  </Link>
                )}
              </Col>
            ))}
            <Col sm={12} md={6} className="stacked">
              <Row>
                {data.works.slice(2, 4).map((project, index) => (
                  <Col sm={12} className="grid-item-wrapper">
                    {project.isExternalLink ? (
                      <a href={`${project.link}`} key={project.link} target="_blank" rel="noopener noreferrer">
                        <div
                          className={`grid-item`}
                          style={{
                            backgroundImage: `url(${project.image.data.attributes.url})`,
                          }}
                        >
                          <div className="grid-item-overlay"></div>
                          <div className="grid-item-inset"></div>
                          <div className={`svg-container-project ${project.link}`}>
                            <GetSVG url={project.logo.data.attributes.url} />
                          </div>
                        </div>
                        </a>
                    ) : (
                      <Link to={`/works/${project.link}`} key={project.link}>
                        <div
                          className={`grid-item`}
                          style={{
                            backgroundImage: `url(${project.image.data.attributes.url})`,
                          }}
                        >
                          <div className="grid-item-overlay"></div>
                          <div className="grid-item-inset"></div>
                          <div className={`svg-container-project ${project.link}`}>
                            <GetSVG url={project.logo.data.attributes.url} />
                          </div>
                        </div>
                      </Link>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
            {data.works.slice(4).map((project, index) => (
              <Col sm={12} className="grid-item-wrapper">
                {project.isExternalLink ? (
                  <a href={`${project.link}`} key={project.link} target="_blank" rel="noopener noreferrer">
                    <div
                      className={`grid-item`}
                      style={{
                        backgroundImage: `url(${project.image.data.attributes.url})`,
                      }}
                    >
                      <div className="grid-item-overlay"></div>
                      <div className="grid-item-inset"></div>
                      <div className={`svg-container-project ${project.link}`}>
                        <GetSVG url={project.logo.data.attributes.url} />
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link to={`/works/${project.link}`} key={project.link}>
                    <div
                      className={`grid-item`}
                      style={{
                        backgroundImage: `url(${project.image.data.attributes.url})`,
                      }}
                    >
                      <div className="grid-item-overlay"></div>
                      <div className="grid-item-inset"></div>
                      <div className={`svg-container-project ${project.link}`}>
                        <GetSVG url={project.logo.data.attributes.url} />
                      </div>
                    </div>
                  </Link>
                )}
              </Col>
            ))}
          </Row>
        </div>
        </Element>
      </Container>
    </section>
  );
};

export default FeaturedProjectsBlock;
