import React, { useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/Footer/Footer';
import { clsx } from 'clsx';
import SEO from '../../utils/SEO';
import NavBar from '../../components/NavBar/NavBar';
import LongButton from '../../components/LongButton/LongButton';
import video404 from '../../assets/404.mp4';

const NotFound = ({ footer }) => {
    const companyInfo = useMemo(() => ({
        address: footer.address,
        email: footer.email,
        linkedinUrl: footer.linkedinUrl,
        twitterUrl: footer.twitterUrl,
        instagramUrl: footer.instagramUrl,
    }), [footer.address, footer.email, footer.linkedinUrl, footer.twitterUrl, footer.instagramUrl]);

    return (

        <div className={clsx('Page', ``)}>
            <SEO
                description={'404 Page Not Found'}
                indexStatus={'true'}
                title={'Looks like we lost you!'}
            />
            <NavBar menuColor='dark' />

            <main className="Page__main">
                <Container fluid className="not-found-container">
                    <Row className="not-found-row">
                        <Col>
                            <video
                                className="video-background"
                                src={video404}
                                autoPlay
                                loop
                                muted
                            />
                            <div className="video-overlay"></div>
                            <div className="not-found-message">
                                <h1>404</h1>
                                <p>Oops! It looks like we lost you.</p>
                                <LongButton className="not-found-button" text={'Return to us'} link={'/'} color={'white'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>

            <Footer
                className="Page__footer"
                footer={footer}
                companyInfo={companyInfo}
            />
        </div>
    );
};

export default NotFound;
