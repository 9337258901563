import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import BackgroundImage from '../../../BackgroundImage/BackgroundImage';
import useHeaderAs from '../../hooks/useHeaderAs';
import Markdown from 'markdown-to-jsx';

const WorkHeroBlock = ({ data, className, ...rest }) => {
  // console.log(data)
  const headerTextColor = 'white';
  const [ref, headerAs] = useHeaderAs('h1', 'h2');

  return (
    <section className={clsx('WorkHeroBlock', className)}>
    <div className={clsx('WorkHeroBlock__overlay')}></div>
      <div
        className={clsx('WorkHeroBlock__header__wrapper', headerTextColor)}
        ref={ref}
        {...rest}
        
      >
        {headerAs && (() => {
          const Header = headerAs;
          return (
            <Header className="WorkHeroBlock__header">
              {parse(data.header)}
            </Header>
           
          );
        })()}
        <Markdown options={{ forceBlock: true }}>{data.body}</Markdown>
        
        
      </div>
      
      <BackgroundImage
        className="WorkHeroBlock__backgroundImage"
        image={data.image}
        full={true}
      />
    </section>
  );
};

export default WorkHeroBlock;
