import React, { useMemo } from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

const Tile = ({
  body,
  className,
  header,
  headerAs = 'h3',
  image,
  linkText,
  linkSlug,
}) => {
  const Header = headerAs;
  const [alternativeText, formats] = useMemo(() => [
    get(image, 'data.attributes.alternativeText'),
    get(image, 'data.attributes.formats'),
  ], [image]);

  return (
    <div className={clsx('Tile__wrapper', className)}>
      <div className="Tile">
        {formats?.thumbnail.url && (<div className="Tile__image__wrapper">
          <img
            alt={alternativeText}
            className="Tile__image"
            src={formats?.thumbnail.url}
          />
        </div>)}
        <Header className="Tile__header">
          {parse(header)}
        </Header>
        <Markdown
          className="Tile__body"
          options={{ forceBlock: true }}
        >
          {body}
        </Markdown>
        {linkText && linkSlug && (
        <Link to={linkSlug} className="Tile__link">
          {linkText}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.9 284.9">
            <path d="M7.4 254.7c-1.9 1.9-2.9 4.1-2.9 6.6s.9 4.7 2.9 6.6l14.3 14.3c1.9 1.9 4.1 2.9 6.6 2.9s4.7-.9 6.6-2.9l133-133c1.9-1.9 2.9-4.1 2.9-6.6s-.9-4.7-2.9-6.6L34.9 3c-2-2-4.2-3-6.6-3-2.5 0-4.7 1-6.6 2.9L7.4 17.1c-1.9 1.9-2.8 4.1-2.8 6.6s.9 4.7 2.8 6.6l112.2 112.2L7.4 254.7z"></path>
          </svg>
        </Link>
        )}

      </div>

    </div>
  );
};

export default Tile;
