import axios from 'axios';
import network from './Network';

export const fetchAppData = async (token) => {
  const [announcement, footer, works, pages, expertises] = await Promise.all([
    axios.get(network.apiBase + 'announcement', { cancelToken: token }),
    axios.get(network.apiBase + 'footer?populate=*', { cancelToken: token }),
    axios.get(network.apiBase + 'works?pagination[limit]=50&populate=deep', { cancelToken: token }),
    axios.get(network.apiBase + 'pages?pagination[limit]=50&populate=deep', { cancelToken: token }),
    axios.get(network.apiBase + 'expertises?pagination[limit]=50&populate=deep', { cancelToken: token }),
  ]);

  return {
    announcement: announcement.data.data,
    footer: footer.data.data,
    works: works.data.data,
    pages: pages.data.data,
    expertises: expertises.data.data,
  };
};
