import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import SEO from '../../utils/SEO';
import Announcement from '../../components/Announcement/Announcement';
import Footer from '../../components/Footer/Footer';
// import MainMenus from '../../components/MainMenus/MainMenus';
import PageBlocks from '../../components/PageBlocks/PageBlocks';
import NavBar from '../../components/NavBar/NavBar';

const Page = ({
  announcement,
  footer,
  // mainMenus,
  works,
  page,
  additionalClasses
}) => {
  const {
    hideFromSiteMap,
    pageBlocks,
    pageDescriptionSEO,
    pageTitleSEO,
    slug,
    menuColor
  } = page;
  console.log(page)

  const companyInfo = useMemo(() => ({
    address: footer.address,
    email: footer.email,
    linkedinUrl: footer.linkedinUrl,
    twitterUrl: footer.twitterUrl,
    instagramUrl: footer.instagramUrl,
  }), [footer.address, footer.email, footer.linkedinUrl, footer.twitterUrl, footer.instagramUrl]);

  // on mount, scroll to top of page
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  // adjust position of main links based on
  // whether or not the banner is visible
  const announcementBannerRef = useRef(null);
  const [announcementBannerHeight, setannouncementBannerHeight] = useState(0);
  useEffect(() => {
    const resize = () => {
      const height = announcementBannerRef.current?.offsetHeight;

      if (height) {
        setannouncementBannerHeight(height);
      }
    };
    const debouncedResize = debounce(resize, 300);

    if (announcement.showBanner && announcementBannerRef.current) {
      resize();
      window.addEventListener('resize', debouncedResize, false);
    }

    return () => window.removeEventListener('resize', debouncedResize, false);
  }, [announcement.showBanner]);

  return (
    <div className={clsx('Page', `page-${slug} ${additionalClasses}`)}>
      <SEO
        description={pageDescriptionSEO}
        indexStatus={hideFromSiteMap}
        title={pageTitleSEO}
      />

      <Announcement
        announcement={announcement}
        popupClassName="Page__announcement__popup"
        ref={announcementBannerRef}
      />

      {/* <MainMenus
        announcementBannerHeight={announcementBannerHeight}
        className="Page__mainMenus"
        companyInfo={companyInfo}
        // mainMenus={mainMenus}
      /> */}
      <NavBar menuColor={menuColor}/>

      <main className="Page__main">
        <PageBlocks
          pageBlocks={pageBlocks}
          works={works}
        />
      </main>

      <Footer
        className="Page__footer"
        companyInfo={companyInfo}
        footer={footer}
      />
    </div>
  );
}

export default Page;
