import React from 'react';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import LongButton from '../../../LongButton/LongButton';

const GlobalHeroBlock = ({ data, className, ...rest }) => {
  console.log(data)

  return (
    <section className={clsx('GlobalHeroBlock', className)}>
      <Row className="align-items-center justify-content-center">
      <Col sm={12} md={6} lg={5} className="GlobalHeroBlock__text-wrapper">
      <h1>{data.header}</h1>
      <LongButton link="/contact" isExternal={false} className="dark black" text="Work with Us"/>

      </Col>
      <Col sm={12} md={6} lg={7} className="GlobalHeroBlock__image-wrapper">
      <img src={data.image.data.attributes.url} alt={data.image.data.attributes.name} />
      </Col>
    </Row>
    </section>
  );
};

export default GlobalHeroBlock;
